<script>
import "../flyers/index_flyers.scss";
import Layout from "../../layouts/main";
import appConfig from "@/app.config.json";

import axios from "axios";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

/**
 * Gallery component
 */
export default {
  page: {
    title: "커스텀광고 이미지관리",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "커스텀광고 이미지관리",
      items: "크리톡 모바일웹에 게시될 전단이미지를 관리합니다.",
      btnName: "행사등록",
      filterCategory: "all",
      visible: false,
      index: 0, // default: 0
      today: "",
      flyersList: [], //기존행사목록
      regVisible: false, //등록할 행사 정보 기입란 보이기 위한 flag
      btnVisible: true, //행사등록버튼 보이기 위한 flag
      eventTitle: "", //등록할 행사 행사명
      sdate: "", //등록할 행사 시작일
      edate: "", //등록할 행사 종료일
      flyersDisableSDate: [], //기존행사 시작일을 모아둔 array (형태: 20220504)
      flyersDisableEDate: [], //기존행사 종료일을 모아둔 array (형태: 20220504)
      sValidationCheckDone: false, //등록할 행사 시작일 validation 체크 완료 여부
      eValidationCheckDone: false, //등록할 행사 종료일 validation 체크 완료 여부
      isModifying: false, //기존행사 수정요청건인지 확인할 flag
      modifyingSrl: "", //수정할 기존행사 srl
      config: {
        enableTime: false,
        minDate: "today",
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      modifyingListIdx: "",
    };
  },

  computed: {
    ...mapState(loggedInfoObject),
  },

  mounted() {
    this.callFlyersList();
    this.setNowTimes();
  },

  components: {
    Layout,
    flatPickr,
  },

  methods: {

    //오늘 날짜 가져오기
    setNowTimes() {
      this.today = new Date().toJSON().slice(0, 10);
      this.sdate = this.today;
      this.edate = this.today;
      this.isModifying = false;
      this.modifyingListIdx = "";
    },

    //전단행사 목록 불러오기
    callFlyersList() {
      this.regVisible = false;
      this.btnVisible = true;
      this.eventTitle = "";
      this.isModifying = false;
      this.modifyingSrl = "";
      axios
          .get(
              `/api/web/market/v/leaflet/info/${this.logged_info.mart_srl}`
          )
          .then((response) => {
            //console.log(response.data)
            this.flyersList = response.data.reverse();
            var arrS = new Array();
            var arrE = new Array();
            for (let i = 0; i < this.flyersList.length; i++) {
              arrS[i] = ("s_date", this.flyersList[i].s_date.replaceAll("-", ""));
              arrE[i] = ("e_date", this.flyersList[i].e_date.replaceAll("-", ""));

              //console.log("disabledate : " + arrS[i])
            }
            this.flyersDisableSDate = arrS;
            this.flyersDisableEDate = arrE;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    //해당 전단행사 삭제
    deleteFlyerList: function (leaflet_srl) {
      axios
          .delete(
              `/api/web/market/v/leaflet/info/${this.logged_info.mart_srl}`,
              {
                data: {
                  leaflet_info_srl: leaflet_srl,
                  market_srl: this.logged_info.mart_srl,
                },
              }
          )
          .then(() => {
            //console.log(response.data)
            this.callFlyersList();
            this.setNowTimes();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    //해당행사 이미지 관리 페이지로 이동
    pageToManageImages: function (leaflet_name, leaflet_srl, s_date, e_date) {
      console.log('vertical Page', leaflet_name + " ,   " + leaflet_srl + " ,   " + s_date  + " ,   " + e_date)
      this.$router.push({
        name: "verticalImageManager",
        params: {
          leaflet_name: leaflet_name,
          leaflet_srl: leaflet_srl,
          event_s_date: s_date,
          event_e_date: e_date,
        },
      });
    },

    //행사 삭제 확인창
    eventDel(title, leaflet_srl) {
      this.$bvModal.msgBoxConfirm(`[${title}] 전단행사를 삭제하시겠습니까?`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              this.deleteFlyerList(leaflet_srl);
            }
          })
    },

    //행사 등록 및 수정을 위한 요소 보이기
    showToReg(status, title, sdate, edate, idx) {
      this.regVisible = true;
      this.btnVisible = false;
      if (status != "new") {
        this.isModifying = true;
        this.modifyingSrl = status;
        this.eventTitle = title;
        this.sdate = sdate;
        this.edate = edate;
        this.modifyingListIdx = idx;
      }
      return this.regVisible, this.btnVisible;
    },

    //행사등록 및 수정을 위한 요소 숨기기
    resetElements() {
      this.regVisible = false;
      this.btnVisible = true;
      this.sdate = this.today;
      this.edate = this.today;
      this.eventTitle = "";
      this.isModifying = false;
      this.modifyingListIdx = "";
      this.sValidationCheckDone = false;
      this.eValidationCheckDone = false;
      return this.regVisible;
    },

    //행사 등록 및 수정 내용 validation 체크 및 서버에 등록
    addEvent() {
      let tmp = "";
      let ready = true;
      if (this.eventTitle === "") {
        tmp = "행사명";
        ready = false;
      } else if (this.sdate === "") {
        tmp = "시작일";
        ready = false;
      } else if (this.edate === "") {
        tmp = "종료일";
        ready = false;
      }
      this.sValidationCheckDone = false;
      //console.log("sdateValidate")
      var tmpSdate = parseInt(this.sdate.replaceAll("-", ""));
      if (this.flyersDisableSDate.length > 0) {
        for (var i = 0; i < this.flyersDisableSDate.length; i++) {
          //console.log("sdateValidate  " + tmpSdate + "<=" + this.flyersDisableSDate[i] + "  ,  " + tmpSdate + "<=" + this.flyersDisableEDate[i])
          if (tmpSdate <= this.flyersDisableEDate[i]) {
            if (this.flyersDisableSDate[i] <= tmpSdate) {
              this.$bvModal.msgBoxOk(`시작일을 확인해주세요.\n겹치는 행사 일정이 있습니다.`, msgModalOptCenter);
              return;
            }
          } else if (
              this.edate != "" &&
              tmpSdate > this.edate.replaceAll("-", "")
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter);
            return;
          } else if (
              this.edate != "" &&
              this.sdate.replaceAll("-", "") <= this.flyersDisableSDate[i] &&
              this.edate.replaceAll("-", "") >= this.flyersDisableEDate[i]
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter);
            return;
          }
          this.sValidationCheckDone = true;
        }
      } else {
        this.sValidationCheckDone = true;
      }
      this.eValidationCheckDone = false;
      //console.log("edateValidate")
      var tmpEdate = parseInt(this.edate.replaceAll("-", ""));
      if (this.flyersDisableEDate.length > 0) {
        for (var id = 0; id < this.flyersDisableSDate.length; id++) {
          //console.log("edateValidate  " + tmpEdate + "<=" + this.flyersDisableSDate[id] + "  ,  " + tmpEdate + ">=" + this.flyersDisableEDate[id])
          if (tmpEdate <= this.flyersDisableEDate[id]) {
            if (this.flyersDisableSDate[id] <= tmpEdate) {
              console.log(
                  "edateValidate  " +
                  tmpEdate +
                  "<=" +
                  this.flyersDisableSDate[id] +
                  "  ,  " +
                  tmpEdate +
                  ">=" +
                  this.flyersDisableEDate[id]
              );
              this.$bvModal.msgBoxOk(`종료일을 확인해주세요.\n겹치는 행사 일정이 있습니다.`, msgModalOptCenter)
              return;
            }
          } else if (
              this.sdate != "" &&
              tmpEdate < this.sdate.replaceAll("-", "")
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)

            return;
          } else if (
              this.sdate != "" &&
              this.sdate.replaceAll("-", "") <= this.flyersDisableSDate[id] &&
              this.edate.replaceAll("-", "") >= this.flyersDisableEDate[id]
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)
            return;
          }
          this.eValidationCheckDone = true;
        }
      } else {
        if (this.edate.replaceAll("-", "") < this.today.replaceAll("-", "")) {
          this.$bvModal.msgBoxOk("행사종료일이 오늘보다 이전 날짜 입니다. \n종료일을 확인해주세요.", msgModalOptCenter)
          this.eValidationCheckDone = false;
        } else {
          this.eValidationCheckDone = true;
        }
      }
      if (!ready) {
        this.$bvModal.msgBoxOk(`[${tmp}]을 확인해주세요.`, msgModalOptCenter)
      } else if (!this.eValidationCheckDone || !this.sValidationCheckDone) {
        console.log("this.eValidationCheckDone : " + this.eValidationCheckDone);
        console.log("this.sValidationCheckDone : " + this.sValidationCheckDone);
        this.$bvModal.msgBoxOk(`유효한 행사일인지 확인중입니다.\n다시 시도해주세요.`, msgModalOptCenter)
      } else if (
          ready &&
          this.sValidationCheckDone &&
          this.eValidationCheckDone
      ) {
        console.log(
            this.eventTitle +
            " , " +
            this.sdate +
            " , " +
            this.edate +
            " , " +
            this.logged_info.mart_srl
        );
        const fd = new FormData();
        fd.append("title", this.eventTitle);
        fd.append("s_date", this.sdate);
        fd.append("e_date", this.edate);
        fd.append("market_srl", this.logged_info.mart_srl);

        axios
            .post(
                `/api/web/market/v/leaflet/info/${this.logged_info.mart_srl}`,
                fd
            )
            .then((response) => {
              console.log(response.data);

              this.$bvModal.msgBoxOk("전단행사가 등록되었습니다.", msgModalOptCenter)

              this.callFlyersList();
              this.setNowTimes();
              return;
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },

    //행사 수정 내용 validation 체크 및 서버에 등록
    modifyEvent() {
      let tmp = "";
      let ready = true;
      if (this.eventTitle === "") {
        tmp = "행사명";
        ready = false;
      } else if (this.sdate === "") {
        tmp = "시작일";
        ready = false;
      } else if (this.edate === "") {
        tmp = "종료일";
        ready = false;
      }
      this.sValidationCheckDone = false;
      this.eValidationCheckDone = false;
      console.log("sdateValidate");
      var tmpSdate = parseInt(this.sdate.replaceAll("-", ""));
      if (this.flyersDisableSDate.length > 1) {
        for (var i = 0; i < this.flyersDisableSDate.length; i++) {
          console.log(
              "sdateValidate  " +
              tmpSdate +
              "<=" +
              this.flyersDisableSDate[i] +
              "  ,  " +
              tmpSdate +
              "<=" +
              this.flyersDisableEDate[i]
          );
          if (
              tmpSdate <= this.flyersDisableEDate[i] &&
              this.modifyingListIdx != i
          ) {
            if (this.flyersDisableSDate[i] <= tmpSdate) {
              this.$bvModal.msgBoxOk(`시작일을 확인해주세요.\n겹치는 행사 일정이 있습니다.`, msgModalOptCenter)
              return;
            }
          } else if (
              this.edate != "" &&
              tmpSdate > this.edate.replaceAll("-", "") &&
              this.modifyingListIdx != i
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)
            return;
          } else if (
              this.edate != "" &&
              this.sdate.replaceAll("-", "") <= this.flyersDisableSDate[i] &&
              this.edate.replaceAll("-", "") >= this.flyersDisableEDate[i] &&
              this.modifyingListIdx != i
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)
            return;
          }
          if (this.modifyingListIdx != i) {
            this.sValidationCheckDone = true;
          }
        }
      } else {
        this.sValidationCheckDone = true;
        this.eValidationCheckDone = true;
      }

      console.log("edateValidate");
      var tmpEdate = parseInt(this.edate.replaceAll("-", ""));
      if (!this.eValidationCheckDone && this.flyersDisableEDate.length > 0) {
        for (var id = 0; id < this.flyersDisableSDate.length; id++) {
          console.log(
              "edateValidate  " +
              tmpEdate +
              "<=" +
              this.flyersDisableSDate[id] +
              "  ,  " +
              tmpEdate +
              ">=" +
              this.flyersDisableEDate[id]
          );
          if (
              tmpEdate <= this.flyersDisableEDate[id] &&
              this.modifyingListIdx != id
          ) {
            if (this.flyersDisableSDate[id] <= tmpEdate) {
              console.log(
                  "edateValidate  " +
                  tmpEdate +
                  "<=" +
                  this.flyersDisableSDate[id] +
                  "  ,  " +
                  tmpEdate +
                  ">=" +
                  this.flyersDisableEDate[id]
              );
              this.$bvModal.msgBoxOk(`종료일을 확인해주세요.\n겹치는 행사 일정이 있습니다.`, msgModalOptCenter)
              return;
            }
          } else if (
              this.sdate != "" &&
              tmpEdate < this.sdate.replaceAll("-", "") &&
              this.modifyingListIdx != id
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)
            return;
          } else if (
              this.sdate != "" &&
              this.sdate.replaceAll("-", "") <= this.flyersDisableSDate[id] &&
              this.edate.replaceAll("-", "") >= this.flyersDisableEDate[id] &&
              this.modifyingListIdx != id
          ) {
            this.$bvModal.msgBoxOk(`행사 시작일과 종료일을 확인해주세요.`, msgModalOptCenter)
            return;
          }
          if (this.modifyingListIdx != id) {
            this.eValidationCheckDone = true;
          }
        }
      } else {
        if (this.edate.replaceAll("-", "") < this.today.replaceAll("-", "")) {
          this.$bvModal.msgBoxOk("행사종료일이 오늘보다 이전 날짜 입니다. \n종료일을 확인해주세요.", msgModalOptCenter)
          this.eValidationCheckDone = false;
        } else {
          this.eValidationCheckDone = true;
        }
      }
      if (!ready) {
        this.$bvModal.msgBoxOk(`[${tmp}]을 확인해주세요.`, msgModalOptCenter)
      } else if (!this.eValidationCheckDone || !this.sValidationCheckDone) {
        console.log("this.eValidationCheckDone : " + this.eValidationCheckDone);
        console.log("this.sValidationCheckDone : " + this.sValidationCheckDone);
        this.$bvModal.msgBoxOk(`유효한 행사일인지 확인중입니다.\n다시 시도해주세요.`, msgModalOptCenter)
      } else if (
          ready &&
          this.sValidationCheckDone &&
          this.eValidationCheckDone
      ) {
        console.log(
            this.eventTitle +
            " , " +
            this.sdate +
            " , " +
            this.edate +
            " , " +
            this.logged_info.mart_srl
        );
        const fd = new FormData();
        fd.append("title", this.eventTitle);
        fd.append("s_date", this.sdate);
        fd.append("e_date", this.edate);
        fd.append("market_srl", this.logged_info.mart_srl);
        if (!this.isModifying) {
          axios
              .post(
                  `/api/web/market/v/leaflet/info/${this.logged_info.mart_srl}`,
                  fd
              )
              .then((response) => {
                console.log(response.data);

                this.$bvModal.msgBoxOk("전단행사가 등록되었습니다.", msgModalOptCenter)

                this.callFlyersList();
                this.setNowTimes();
                return;
              })
              .catch((error) => {
                console.log(error);
              });
        } else {
          fd.append("leaflet_info_srl", this.modifyingSrl);
          axios
              .put(
                  `/api/web/market/v/leaflet/info/${this.logged_info.mart_srl}`,
                  fd
              )
              .then((response) => {
                console.log(response.data);

                this.$bvModal.msgBoxOk("전단행사가 수정되었습니다.", msgModalOptCenter)

                this.callFlyersList();
                this.setNowTimes();
                return;
              })
              .catch((error) => {
                console.log(error);
              });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="FlyersPageArea">
      <!--      제목영역(TitleArea)-->
      <div class="titleArea page-title-box">
        <span style="float: left; padding-left: 8px">
          <h4 class="mb-0 font-size-18" style="display: inline">{{ title }}</h4>
          <span
              :items="items"
              class="font-size-14"
              style="color: #777777; text-align: left; display: block"
          >{{ items }}</span
          >
        </span>
        <span
            class="page-title-right d-flex align-items-center md-0"
            style="float: right; padding-right: 8px"
        >
          <!--          <a
              href="javascript: void(0);"
              class="btn btn-primary btn-soft w-100"
              @click="showToReg('new', '', '', '')"
              v-if="btnName !== ''"
          >
            {{ btnName }}
          </a>-->
        </span>
      </div>

      <!--    컨텐츠 영역(ContentArea)-->
      <div class="contentArea row">
        <!--      행사정보등록-->
        <div class="flyersCreate col-xl-4 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">행사정보 등록</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-12">
                  <div class="mb-3 row">
                    <label
                        for="example-text-input"
                        class="col-md-2 col-form-label"
                    >행사명</label
                    >
                    <div class="col-md-10">
                      <input
                          class="form-control"
                          type="text"
                          value="this.eventTitle"
                          id="example-text-input"
                          maxlength="20"
                          v-model="eventTitle"
                      />
                    </div>
                  </div>

                  <div class="row mb-3 mt-3 mt-xl-0">
                    <label for="s-date-input" class="col-md-2 col-form-label"
                    >시작일</label
                    >
                    <div class="col-md-10">
                      <flat-pickr
                          class="form-control"
                          value="this.sdate"
                          v-model="sdate"
                          :config="config"
                          id="s-date-input"
                      >
                      </flat-pickr>
                    </div>
                  </div>

                  <div class="row mb-3 mt-3 mt-xl-0">
                    <label for="e-date-input" class="col-md-2 col-form-label"
                    >종료일</label
                    >
                    <div class="col-md-10">
                      <flat-pickr
                          class="form-control"
                          value="this.edate"
                          v-model="edate"
                          :config="config"
                          id="e-date-input"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
                <div
                    class="mt-2"
                    style="
                    position: relative;
                    display: flex;
                    vertical-align: middle;
                  "
                    v-if="!isModifying"
                >
                  &nbsp;
                  <button
                      type="submit"
                      class="btn btn-primary w-md"
                      style="
                      position: absolute;
                      right: 0;
                      margin-bottom: 30px;
                      margin-right: 10px;
                    "
                      @click="addEvent"
                  >
                    등록
                  </button>
                </div>
                <div
                    class="mt-2"
                    style="
                    position: relative;
                    display: flex;
                    vertical-align: middle;
                  "
                    v-if="isModifying"
                >
                  &nbsp;
                  <b-button
                      type="submit"
                      class="btn btn-primary w-md"
                      variant="danger"
                      style="
                      position: absolute;
                      margin-bottom: 30px;
                      margin-right: 10px;
                    "
                      @click="resetElements"
                  >수정취소
                  </b-button>
                  <button
                      type="submit"
                      class="btn btn-primary w-md"
                      style="
                      position: absolute;
                      right: 0;
                      margin-bottom: 30px;
                      margin-right: 10px;
                    "
                      @click="modifyEvent"
                  >
                    수정
                  </button>
                </div>
                &nbsp;
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
        <!--        전단행사 리스트-->
        <div class="flyersList col-xl-8 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">전단행사 리스트</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="table-light" style="text-align: center">
                  <tr>
                    <th>순번</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>행사명 (이미지개수)</th>
                    <th>이미지 관리</th>
                    <th>수정/삭제</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(row, idx) in this.flyersList"
                      :key="row.leaflet_info_srl"
                      style="vertical-align: middle"
                  >
                    <th scope="row" style="text-align: center">
                      {{ idx + 1 }}
                    </th>
                    <td style="text-align: center">{{ row.s_date }}</td>
                    <td style="text-align: center">{{ row.e_date }}</td>

                    <td>{{ row.title }} ( {{ row.cnt }} 장 )</td>
                    <td style="text-align: center">
                      <b-button
                          variant="primary"
                          @click="
                            pageToManageImages(
                              row.title,
                              row.leaflet_info_srl,
                              row.s_date,
                              row.e_date
                            )
                          "
                      >
                        이미지 관리
                      </b-button>
                    </td>
                    <td style="text-align: center">
                      <b-button
                          variant="outline-primary"
                          style="margin-right: 5px"
                          @click="
                            showToReg(
                              row.leaflet_info_srl,
                              row.title,
                              row.s_date,
                              row.e_date,
                              idx
                            )
                          "
                      >
                        수정
                      </b-button>
                      <b-button
                          variant="outline-danger"
                          @click="eventDel(row.title, row.leaflet_info_srl)"
                      >
                        삭제
                      </b-button>
                    </td>
                  </tr>
                  </tbody>
                  <!-- end tbody-->
                </table>
                <!-- end table-->
              </div>
              <!-- end table responsive -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>

    </div>
  </Layout>
</template>
